import { useState } from 'react'
import { useConnect, useAccount } from 'wagmi'


// Setup: npm install alchemy-sdk
// Github: https://github.com/alchemyplatform/alchemy-sdk-js
import { Network, Alchemy } from "alchemy-sdk";

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
    apiKey: '0XvdV22GUFtEtrBERMy9YBGQ5MKQVT7L', // Replace with your Alchemy API Key. TODO: Replace with your API key.!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    network: Network.ETH_MAINNET, // Replace with your network.

};

const alchemy = new Alchemy(settings);



export function Profile() {

    const [userNFTs, setUserNFTs] = useState([]);

    const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect();

    const { address, isConnecting, isDisconnected } = useAccount();


    const getUserNfts = async () => {
        if (address) {
            // Print all NFTs returned in the response:
            alchemy.nft.getNftsForOwner(address).then((data) => {
                console.log(data);
                setUserNFTs(data.ownedNfts);
            });
        }

    };

    return (
        <div>
            {connectors.map((connector) => (
                <button
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => connect({ connector })}
                >
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    {isLoading &&
                        connector.id === pendingConnector?.id &&
                        ' (connecting)'}
                </button>
            ))}

            {error && <div>{error.message}</div>}
            <br></br><br></br><br></br><br></br>
            <button onClick={getUserNfts}>GET NFT</button>
            {userNFTs &&
                <div>
                    {userNFTs.map((nft) => (
                        <div key={nft.tokenId}> {nft.title} </div>
                    ))}
                </div>
            }
        </div>
    )
}
